/* eslint-disable react/no-unstable-nested-components */
import colors from '@/constants/colors';
import { useModelOutputs } from '@/hooks/useModelOutputs';
import { bannerAssortmentChartDateFormatter } from '@/utils/dateHelpers';
import React from 'react';
import NoDataCard from 'andromeda/noDataCard';
import { formatUseCaseName } from '../utils/UseCaseDropdown';
import CommonTable from './commonTable';
import { ModelOutput } from '../console/experiments/DMS/types/ModelOutput';
import { BEToolBadge, formatNamespaceForDisplay, STATUS_COLORS, USE_CASES_COLORS } from './utils';

const headers = {
    id: 'ID',
    namespace: 'Namespace',
    organization: 'Organization',
    use_case: 'use case',
    status: 'Status',
    status_description: 'Status Description',
    ingested_time: 'Ingested time',
    model_id: 'Model ID',
    s3_path: 'S3 path',
};

function ModelOutputTable({
    namespace,
    usecase,
    getGeneratedColor,
    height,
    onHeightChange,
}: {
    namespace: string;
    usecase: string;
    getGeneratedColor: (k: string) => string;
    height: number;
    onHeightChange: (n: number) => void;
}) {
    const { data, isLoading } = useModelOutputs({
        disabled: false,
        namespace,
        usecase,
    });

    const bodyCellsFormatter: Partial<
        Record<keyof ModelOutput, (item: ModelOutput) => JSX.Element | string>
    > = {
        namespace: item =>
            formatNamespaceForDisplay(item.namespace, getGeneratedColor(item.namespace)),
        organization: item => (
            <BEToolBadge color={getGeneratedColor(item.organization)}>
                {item.organization}
            </BEToolBadge>
        ),
        use_case: item => (
            <BEToolBadge color={USE_CASES_COLORS?.[item.use_case] ?? colors.yellow}>
                {formatUseCaseName(item.use_case)}
            </BEToolBadge>
        ),
        status: item => (
            <BEToolBadge color={STATUS_COLORS?.[item.status] ?? colors.orange}>
                {item.status}
            </BEToolBadge>
        ),
        ingested_time: item => bannerAssortmentChartDateFormatter(item.ingested_time, ' HH:MM:SS'),
    };

    if (!namespace)
        return (
            <div className="flex h-60 w-full items-center justify-center">
                <NoDataCard>Select a namespace</NoDataCard>
            </div>
        );

    return (
        <CommonTable
            data={data}
            headers={headers}
            bodyCellsFormatter={bodyCellsFormatter}
            loading={isLoading}
            defaultSortingColumn="ingested_time"
            height={height}
            onHeightChange={onHeightChange}
        />
    );
}

export default ModelOutputTable;
