import useRegisterHost, { HostBodyType } from '@/hooks/useRegisterHost';
import { Input, Modal, PasswordInput } from '@mantine/core';
import { Button, Text } from 'andromeda';
import React, { useCallback, useState } from 'react';
import SelectLabelWrapper from 'andromeda/selects/selectLabelWrapper';
import { useAppContext } from '@/contexts/appContext';
import classNames from 'classnames';

function RegisterHostModel() {
    const { user } = useAppContext();
    const isArenaStaff = user?.groups?.includes('arena-staff');

    const [isOpen, setIsOpen] = useState(false);
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [errors, setErrors] = useState<Record<keyof HostBodyType, boolean>>();
    const { isPending, mutateAsync } = useRegisterHost();
    const [organization, setOrganization] = useState('borealis');
    const [token, setToken] = useState<string>('');

    const resetState = useCallback(() => {
        setIsConfirmation(false);
        setOrganization('borealis');
        setToken('');
        setErrors(undefined);
    }, []);

    if (!isArenaStaff) return null;
    return (
        <>
            <Button onClick={() => setIsOpen(true)} inactive={isPending} disabled={isPending}>
                Register Host
            </Button>
            <Modal
                opened={isOpen}
                onClose={() => {
                    setIsOpen(false);
                    resetState();
                }}
                title="Register Host"
                withinPortal
                classNames={{
                    header: 'text-navy-solid-70',
                }}
            >
                <div className="flex h-64 flex-col justify-between gap-1">
                    <div>
                        {!isConfirmation ? (
                            <>
                                <SelectLabelWrapper
                                    className="pl-1 pr-2 capitalize"
                                    label="organization"
                                >
                                    <Input
                                        value={organization}
                                        error={errors?.organization}
                                        styles={{
                                            input: {
                                                borderColor:
                                                    errors?.organization && 'red !important',
                                            },
                                        }}
                                        onChange={e => {
                                            setOrganization(e.currentTarget.value);
                                            setErrors(prev => ({ ...prev, organization: false }));
                                        }}
                                        required
                                        placeholder="Enter an Organization"
                                    />
                                </SelectLabelWrapper>
                                <SelectLabelWrapper className="pl-1 pr-2 capitalize " label="Token">
                                    <PasswordInput
                                        value={token}
                                        error={errors?.token}
                                        styles={{
                                            input: {
                                                borderColor: errors?.token && 'red !important',
                                            },
                                        }}
                                        classNames={{
                                            input: classNames({
                                                '!border-border-color': !errors?.token,
                                                '!border-red': errors?.token,
                                            }),
                                            visibilityToggle: '!text-risd-blue',
                                        }}
                                        onChange={e => {
                                            setToken(e.currentTarget.value);
                                            setErrors(prev => ({ ...prev, token: false }));
                                        }}
                                        required
                                        placeholder="Enter your Token"
                                    />
                                </SelectLabelWrapper>
                            </>
                        ) : (
                            <div className="table w-fit">
                                <div className="table-row">
                                    <Text
                                        type="14Reg"
                                        className="table-cell w-fit capitalize !text-navy-solid-70"
                                    >
                                        organization:
                                    </Text>{' '}
                                    <Text type="14Mid" className="table-cell pl-1 !text-navy">
                                        {organization}
                                    </Text>
                                </div>
                                <div className="table-row">
                                    <Text
                                        type="14Reg"
                                        className="table-cell w-fit capitalize !text-navy-solid-70"
                                    >
                                        Token:
                                    </Text>{' '}
                                    <Text type="14Mid" className="table-cell pl-1 !text-navy">
                                        ****
                                    </Text>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex w-full justify-end gap-2 pr-2 pt-3">
                        <Button
                            onClick={() => {
                                if (isConfirmation) {
                                    setIsConfirmation(false);
                                    return;
                                }
                                setIsOpen(false);
                                resetState();
                            }}
                            inactive={isPending}
                            disabled={isPending}
                            variant="secondary"
                        >
                            {isConfirmation ? 'Back' : 'Close'}
                        </Button>
                        <Button
                            onClick={async () => {
                                if (organization.length > 0 && token.length > 0) {
                                    if (isConfirmation) {
                                        await mutateAsync({ organization, token });
                                        setIsOpen(false);
                                        resetState();
                                        return;
                                    }
                                    setIsConfirmation(true);
                                    return;
                                }

                                setErrors({
                                    organization: organization.length === 0,
                                    token: token.length === 0,
                                });
                            }}
                            inactive={isPending}
                            disabled={isPending}
                        >
                            {isConfirmation ? 'Confirm' : 'Submit'}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default RegisterHostModel;
