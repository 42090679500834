import { Button } from '@mantine/core';
import classNames from 'classnames';
import React from 'react';

const TabButton = React.forwardRef(
    (
        {
            leftIcon,
            onClick,
            disabled,
            children,
            isActive,
            id,
            width = 150,
            className,
        }: {
            onClick: () => void;
            leftIcon?: JSX.Element;
            disabled?: boolean;
            children: string;
            className?: string;
            isActive: boolean;
            id: string;
            width?: number | string;
        },
        ref: React.Ref<HTMLButtonElement>,
    ) => (
        <Button
            id={id}
            ref={ref}
            style={{
                width,
                height: '32px',
            }}
            variant="subtle"
            className={classNames(
                `rounded-lg text-sm text-primary-50 cursor-pointer hover:text-primary-80 font-medium disabled:text-navy-solid-70`,
                {
                    '!text-primary': isActive,
                    '!cursor-default': disabled || isActive,
                },
                className,
            )}
            leftSection={leftIcon}
            classNames={{
                loader: 'hidden',
            }}
            onClick={onClick}
            disabled={disabled || isActive}
            type="button"
            loading={false}
            unstyled
        >
            {children}
        </Button>
    ),
);

export default TabButton;
