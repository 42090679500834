import { BOREALIES_API_URL } from '../buildConfig/processEnv';
import useApiQuery from './useApiQuery';

interface DatasetsDataType {
    dataset_name: string;
    s3_path: string;
    ingested_time: string;
    metadata: {
        num_rows: string;
        num_records: string;
        incremental: boolean;
    };
    status: string;
    id: string;
    organization: string;
    namespace: string;
    dataset_type: string;
    athena_relation: string;
    upload_time: string;
    status_description: string;
}

const useDataSets = (namespace: string, isLatest: boolean = false) =>
    useApiQuery<DatasetsDataType[]>({
        url: `${BOREALIES_API_URL}/spaceport/api/data_catalog/datasets?organization=borealis&${namespace ? `namespace=${namespace}` : ''}&latest=${isLatest}`,
    });

export { useDataSets };
export type { DatasetsDataType };
