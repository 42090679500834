import React from 'react';
import classNames from 'classnames';
import { AgentManagerModelList } from '@/components/console/experiments/DMS/hooks/useAgentManagerModelList';
import { CellButton, SortingType } from '../../../../andromeda/tableHeaderCellCarret';
import { KeysOfOverviewData } from './tableColumnsDropdown';
import { PHNestleInspectDataType } from '../../../../hooks/useInspectNamespaceData';

type PricingDetailsSortableColumns =
    | 'country'
    | 'state'
    | 'city'
    | 'operation'
    | 'data_source'
    | 'mode_sale_price'
    | 'mode_shipping_price'
    | 'zip_code'
    | 'mode_seller_minimum_quantity'
    | 'shipping_price'
    | 'seller_minimum_quantity'
    | 'last_updated'
    | 'zip_code';
type PricingDetailsSubTableSortableColumns =
    | 'seller_name'
    | 'sale_price'
    | 'seller_stock_count'
    | 'shipping_price'
    | 'seller_minimum_quantity'
    | 'last_updated'
    | 'zip_code';
type InvoiceTableHeadersKeys = keyof PHNestleInspectDataType['0'];
type AgentManagerModelListKeys = keyof AgentManagerModelList;

function TableHeaderCell<T = string>({
    sorting,
    cell,
    children,
    onClick,
    focusBank,
    handleFocus,
    className,
    iconLeft,
}: {
    sorting: {
        column: T;
        type: SortingType;
    };
    cell: T;
    children: string | JSX.Element;
    iconLeft?: JSX.Element;
    onClick: (value: T) => void;
    handleFocus: (value: Record<T extends string ? string : string, boolean>) => void;
    focusBank: Record<T extends string ? string : string, boolean>;
    className?: string;
}) {
    return (
        <CellButton
            hasFocus={focusBank?.[cell]}
            onClick={() => onClick(cell)}
            sortingType={sorting.column === cell ? sorting.type : false}
            onMouseEnter={() => {
                handleFocus({
                    ...focusBank,
                    [cell as string]: true,
                });
            }}
            onMouseLeave={() => {
                handleFocus({
                    ...focusBank,
                    [cell as string]: false,
                });
            }}
            className={classNames(className, 'capitalize px-[10px] py-[7px]', {
                'text-navy-solid-50': sorting.type && sorting.column === cell,
            })}
            iconLeft={iconLeft}
        >
            {children}
        </CellButton>
    );
}

export default TableHeaderCell;
export type {
    PricingDetailsSortableColumns,
    PricingDetailsSubTableSortableColumns,
    InvoiceTableHeadersKeys,
    AgentManagerModelListKeys,
};
