import colors from '@/constants/colors';
import { COUNTRIES_MAPPED_TO_COUNTRY_LIST } from '@/types/hooks/useTableQuery';
import { Badge } from 'andromeda';
import { countries } from 'countries-list';
import { CONSOLE_MODULE_COLOR_MAP } from '../console/ConsoleModule';

const STATUS_COLORS = {
    FAILED: colors.red,
    SUCCESS: colors.green,
    PENDING: colors.purple,
    EXPORTING: colors.green,
};
const ORGANIZATION_COLORS = {
    arena: colors.yellow,
};

const USE_CASES_COLORS = CONSOLE_MODULE_COLOR_MAP;

const BEToolBadge = ({ color, children }) =>
    children === '' ? (
        '----'
    ) : (
        <Badge
            borderStyle="solid"
            bgColorOpacity={0.55}
            color={color}
            className="whitespace-nowrap !font-semibold !text-navy-solid-70"
        >
            {children}
        </Badge>
    );

const formatCountriesInAnString = (str: string) => {
    const splittingValue = str.includes('-') ? '-' : str.includes('_') ? '_' : ' ';
    const label = str
        .split(splittingValue)
        .reduce(
            (acc: string[], item) => [
                ...acc,
                countries?.[COUNTRIES_MAPPED_TO_COUNTRY_LIST?.[item] ?? item]?.name ?? item,
            ],
            [],
        )
        .join(' ')
        .toLowerCase();
    return label;
};

const formatNamespaceForDisplay = (namespace: string, color: string) => {
    const [country, ...rest] = namespace.split('-');

    let label =
        countries?.[COUNTRIES_MAPPED_TO_COUNTRY_LIST?.[country] ?? country]?.name ?? country;
    if (namespace.includes('-')) {
        label += ` ${rest.join(' ')}`;
    }
    return <BEToolBadge color={color}>{formatCountriesInAnString(namespace)}</BEToolBadge>;
};

const ALL_USED_COLORS = {
    ...STATUS_COLORS,
    ...ORGANIZATION_COLORS,
    ...USE_CASES_COLORS,
};

export {
    STATUS_COLORS,
    USE_CASES_COLORS,
    formatNamespaceForDisplay,
    ORGANIZATION_COLORS,
    ALL_USED_COLORS,
    BEToolBadge,
    formatCountriesInAnString,
};
