import { Skeleton, Table } from '@mantine/core';
import StyledTable from 'andromeda/styledTable';
import { handleCellClickType, SortingType } from 'andromeda/tableHeaderCellCarret';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { NewTypeSelectValues } from 'andromeda/types/select/newTypeOfSelect';
import { Pagination } from 'andromeda';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useViewportSize } from '@mantine/hooks';
import TableHeaderCell from '../pricingIntelligence/pricingDetails/table/tableHeaderCell';
import CommonFilter from './commonFilter';

type HeaderUniq<T> = (arr: T[]) => JSX.Element;

const savedWidth = {
    id: '18vw',
    s3_path: '18vw',
    path_to_artifact: '18vw',
};

function CommonTable<T = any>({
    headers,
    bodyCellsFormatter,
    data,
    loading,
    defaultSortingColumn,
    noSorting,
    deepColumnsFetchersForSorting,
    height,
    onHeightChange,
}: {
    headers: Partial<Record<keyof T, string | HeaderUniq<T>>>;
    bodyCellsFormatter: Partial<Record<keyof T, (item: T) => string | JSX.Element | number>>;
    data: T[];
    loading: boolean;
    defaultSortingColumn?: keyof T;
    noSorting?: { columns?: Partial<Record<keyof T, boolean>>; all?: boolean };
    deepColumnsFetchersForSorting?: Partial<Record<keyof T, (item: T) => string | number>>;
    height: number;
    onHeightChange: (n: number) => void;
}) {
    const [page, setPage] = useState(1);
    const [filterValues, setFilterValues] = useState<NewTypeSelectValues>();
    const [tableHeaderHasFocus, setTableHeaderHasFocus] =
        useState<Record<keyof T extends string ? string : string, boolean>>();
    const { width: viewPortWidth, height: viewPortHeight } = useViewportSize();

    const [sorting, setSorting] = useState<{
        column: keyof T;
        type: SortingType;
    }>({
        column: defaultSortingColumn,
        type: 'desc',
    });

    const filteredData = useMemo(() => {
        if (Object.keys(filterValues ?? {}).length === 0) return data;
        return (
            data?.filter?.(item =>
                Object.entries(filterValues).reduce((acc, [key, val]) => {
                    const v = deepColumnsFetchersForSorting?.[key]?.(item) ?? item?.[key];
                    if (val && val.type === 'range' && typeof v === 'string') {
                        return (
                            acc &&
                            new Date(v ?? 0).getTime() >= val.value[0] &&
                            new Date(v ?? 0).getTime() <= val.value[1]
                        );
                    }
                    if (val && val.type === 'range' && typeof v === 'number') {
                        return acc && (v ?? 0) >= val.value[0] && (v ?? 0) <= val.value[1];
                    }
                    if (val && val.type === 'multiSelect' && typeof v === 'string')
                        return acc && val.value.includes(v);

                    return acc;
                }, true),
            ) ?? []
        );
    }, [data, deepColumnsFetchersForSorting, filterValues]);

    const tableRef = useRef<HTMLDivElement>(null);

    const maxHeight =
        // ? 366 is the page - table space
        viewPortHeight - 200;
    const rowHeight = viewPortWidth > 1200 ? 60 : 95;
    const nPerPage = Math.round(maxHeight / rowHeight);

    const sortedBody = useMemo(
        () =>
            filteredData
                ?.sort((a, b) => {
                    const column = sorting.column as string;
                    const aValue = (deepColumnsFetchersForSorting?.[column]?.(a) ?? a[column]) as
                        | string
                        | number;
                    const bValue = (deepColumnsFetchersForSorting?.[column]?.(b) ?? b[column]) as
                        | string
                        | number;
                    let aX = aValue as string | number;
                    let bX = bValue as string | number;

                    if (typeof aX === 'string' && typeof bX === 'string') {
                        if (column.includes('date') || column.includes('time')) {
                            if (sorting.type === 'asc')
                                return new Date(aX).getTime() - new Date(bX).getTime();
                            return new Date(bX).getTime() - new Date(aX).getTime();
                        }
                        if (sorting.type === 'asc')
                            return (aX || 'not available')?.localeCompare(bX || 'not available');
                        return (bX || 'not available')?.localeCompare(aX || 'not available');
                    }
                    aX = (aValue as number) ?? 0;
                    bX = (bValue as number) ?? 0;
                    if (typeof aX === 'number' && typeof bX === 'number') {
                        if (sorting.type === 'asc') return bX - aX;
                        return aX - bX;
                    }
                    return 1 - 0;
                })
                ?.map((item, x) => (
                    <Table.Tr key={`table-body-row-${JSON.stringify(item)}`}>
                        {Object.keys(headers).map((key, i) => {
                            const val = bodyCellsFormatter[key]?.(item) ?? item[key];
                            return (
                                <Table.Td
                                    key={`table-body-cell-${JSON.stringify(item)}-${key}`}
                                    className={classNames({
                                        '!text-left ': i === 0,
                                    })}
                                    style={{
                                        ...([
                                            'id',
                                            'athena_relation',
                                            's3_path',
                                            'path_to_artifact',
                                        ].includes(key)
                                            ? {
                                                  textTransform: 'none',
                                              }
                                            : {}),
                                        maxWidth: savedWidth?.[key],
                                        minWidth: savedWidth?.[key],
                                    }}
                                >
                                    {val || '----'}
                                </Table.Td>
                            );
                        })}
                    </Table.Tr>
                )) ?? [],
        [
            filteredData,
            deepColumnsFetchersForSorting,
            headers,
            bodyCellsFormatter,
            sorting.column,
            sorting.type,
        ],
    );

    const body = useMemo(
        () =>
            sortedBody.length <= nPerPage
                ? sortedBody
                : [...sortedBody].slice((page - 1) * nPerPage, page * nPerPage),
        [nPerPage, page, sortedBody],
    );
    const tableMaxHeight =
        body.length < nPerPage / 2 ? body.length * rowHeight + 60 : maxHeight - 120;

    const total = Math.ceil(sortedBody.length / nPerPage);
    useEffect(() => {
        if (typeof height === 'number' || typeof maxHeight !== 'number') return;
        onHeightChange(maxHeight);
    }, []);

    return (
        <div
            ref={tableRef}
            style={{
                height: viewPortHeight - 200,
                minHeight: 200,
            }}
            className="flex flex-col overflow-auto"
        >
            <div className="flex justify-end pb-3">
                {/* {viewPortHeight} {tableRef.current?.clientHeight} {maxHeight} {tableMaxHeight}{' '}
                {nPerPage} {body.length} */}
                <CommonFilter
                    headers={headers}
                    data={data}
                    deepColumnsFetchersForSorting={deepColumnsFetchersForSorting}
                    loading={loading}
                    value={filterValues}
                    onChange={setFilterValues}
                />
            </div>
            <motion.div
                animate={{
                    height: tableMaxHeight,
                }}
                initial={{
                    height: tableMaxHeight,
                    minHeight: rowHeight * 2,
                }}
                transition={{ duration: 0.3, ease: 'linear' }}
                className="w-full overflow-hidden"
            >
                <StyledTable
                    maxHeight="100%"
                    minHeight={rowHeight * 2}
                    body={body}
                    headers={Object.entries(headers).map(([key, item]) => (
                        <Table.Td key={key}>
                            {noSorting?.all ||
                            noSorting?.columns?.[key] ||
                            typeof item === 'function' ? (
                                typeof item === 'function' ? (
                                    item(filteredData)
                                ) : (
                                    item
                                )
                            ) : (
                                <TableHeaderCell<keyof T>
                                    cell={key as keyof T}
                                    onClick={() => {
                                        setSorting({
                                            column: key as keyof T,
                                            type: handleCellClickType(
                                                (key === sorting.column && sorting.type) || false,
                                            ),
                                        });
                                    }}
                                    sorting={sorting}
                                    handleFocus={obj =>
                                        setTableHeaderHasFocus(prev => ({
                                            ...prev,
                                            ...obj,
                                        }))
                                    }
                                    focusBank={tableHeaderHasFocus}
                                    className="flex w-full items-center justify-center text-center"
                                >
                                    {item as string}
                                </TableHeaderCell>
                            )}
                        </Table.Td>
                    ))}
                    loading={loading}
                    dataCondition={(filteredData ?? data)?.length === 0}
                    noDataText="There's no data for the selected filters."
                />
            </motion.div>
            <div className="mt-2 flex w-full justify-center justify-self-end">
                {loading ? (
                    <Skeleton style={{ height: 32 }} className="min-h-8 !w-full max-w-[360px]" />
                ) : (
                    total > 1 && (
                        <Pagination total={total} page={page} onChange={setPage} className="pt-4" />
                    )
                )}
            </div>
        </div>
    );
}

export default CommonTable;
