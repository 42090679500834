import colors from '@/constants/colors';
import { useAppContext } from '@/contexts/appContext';
import { notification } from '@/utils/notification';
import { useMutation } from '@tanstack/react-query';
import { BOREALIES_API_URL } from 'buildConfig/processEnv';
import { Check, X } from 'phosphor-react';
import React, { useCallback } from 'react';

type HostBodyType = {
    organization: string;
    token: string;
};

const url = `${BOREALIES_API_URL}/spaceport/api/fusion/register`;
const request = async ({ body, token }: { body: HostBodyType; token: string }) => {
    const apiUrl = new URL(url);

    const response = await fetch(apiUrl.toString(), {
        method: 'POST',
        headers: {
            accept: 'application/json',
            authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) {
        // Handling HTML Response
        const res = await response
            .clone()
            .text()
            .catch(() => {
                throw new Error('Failed to parse response');
            });
        const res2 = await response
            .clone()
            .json()
            .catch(() => {
                throw new Error('Failed to parse response');
            });

        return Promise.reject(
            Object.keys(res2).length > 0
                ? { ...res2, message: res2?.message ?? res2?.detail }
                : res,
        );
    }

    const data = await response.json().catch(() => {
        throw new Error('Failed to parse response');
    });

    return data;
};

const useRegisterHost = () => {
    const { user } = useAppContext();
    const requestWithAuth = useCallback(
        (body: HostBodyType) => request({ body, token: user?.siberiaToken }),
        [user?.siberiaToken],
    );

    return useMutation({
        mutationFn: requestWithAuth,
        onError(e, a) {
            notification({
                message: `Failed: ${e?.message ?? 'Failed'}`,
                icon: <X color={colors.red} size={14} />,
            });
        },
        onSuccess(e, a) {
            notification({
                message: `${e?.message ?? 'Successful'}`,
                icon: <Check color={colors.green} size={14} />,
            });
        },
    });
};

export default useRegisterHost;
export type { HostBodyType };
