import ResponsiveLayout from '@/components/responsive/ResponsiveLayout';
import CatalogComponent from '@/components/catalog/catalogComponent';
import { UserGuard } from '@/components/utils/UserGuard';
import { CATALOG_USER_GROUP_WHITELIST } from '@/components/navBar/constants';

export default function Catalog() {
    return (
        <ResponsiveLayout title="Catalog" className="md:!pb-0">
            <UserGuard userGroupWhitelist={CATALOG_USER_GROUP_WHITELIST}>
                <CatalogComponent />
            </UserGuard>
        </ResponsiveLayout>
    );
}
