/* eslint-disable react/no-unstable-nested-components */
import colors from '@/constants/colors';
import { bannerAssortmentChartDateFormatter } from '@/utils/dateHelpers';
import { HoverCard } from '@mantine/core';
import { Checkbox, Text } from 'andromeda';
import React from 'react';
import { Path, X } from 'phosphor-react';
import {
    ExportedNamespacesAndUseCases,
    useExportedNamespacesAndUseCases,
} from '@/hooks/useExportedNamespacesAndUseCases';
import { generateExportID } from '@/hooks/useUploadData';
import CommonTable from './commonTable';
import { BEToolBadge, formatNamespaceForDisplay, STATUS_COLORS, USE_CASES_COLORS } from './utils';
import { formatUseCaseName } from '../utils/UseCaseDropdown';
import StyledTooltip from '../utils/styledTooltip';

const deepColumnsFetchersForSorting = {
    no_path_to_recs_list: (item: ExportedNamespacesAndUseCases) => item.path_to_recs_list.length,
};

function ExportsTable({
    namespace,
    usecase,
    getGeneratedColor,
    onCheckClick,
    checkedRows,
    isArenaStaff,
    height,
    onHeightChange,
}: {
    namespace: string;
    usecase: string;
    height: number;
    onHeightChange: (n: number) => void;
    isArenaStaff: boolean;
    getGeneratedColor: (k: string) => string;
    onCheckClick: React.Dispatch<
        React.SetStateAction<
            Record<
                string,
                {
                    use_case: string;
                    namespace: string;
                    path_to_artifact: string;
                }
            >
        >
    >;
    checkedRows: Record<
        string,
        Pick<ExportedNamespacesAndUseCases, 'namespace' | 'use_case' | 'path_to_artifact'>
    >;
}) {
    const { data, isLoading } = useExportedNamespacesAndUseCases({ namespace, usecase });
    const headers = {
        ...(isArenaStaff
            ? {
                  ' ': (arr: ExportedNamespacesAndUseCases[] = []) => {
                      const dataWithArtifacts = arr.reduce((acc, item) => {
                          if (!item.path_to_artifact) return acc;
                          const val = {
                              namespace: item.namespace,
                              use_case: item.use_case,
                              path_to_artifact: item.path_to_artifact,
                          };

                          return { ...acc, [generateExportID(val)]: val };
                      }, {});
                      return (
                          <Checkbox
                              onChange={e => {
                                  onCheckClick(e.currentTarget?.checked ? dataWithArtifacts : {});
                              }}
                              defaultChecked={false}
                              size="sm"
                              checked={
                                  (Object.values(checkedRows).length > 0 &&
                                      Object.values(checkedRows).length ===
                                          Object.values(dataWithArtifacts).length) ||
                                  undefined
                              }
                              indeterminate={
                                  (Object.values(checkedRows).length > 0 &&
                                      Object.values(checkedRows).length !==
                                          Object.values(dataWithArtifacts).length) ||
                                  undefined
                              }
                          />
                      );
                  },
              }
            : {}),
        namespace: 'Namespace',
        use_case: 'Use case',
        ...(isArenaStaff ? { path_to_artifact: 'Path to artifact' } : {}),
        status: 'Status',
        start_time: 'Start Date',
        last_updated: 'Last Upload',
        no_path_to_recs_list: '# of Path to Recs',
        path_to_recs_list: 'Path to Recs',
    };

    const bodyCellsFormatter: Partial<
        Record<
            keyof typeof headers,
            (item: ExportedNamespacesAndUseCases) => string | JSX.Element | number
        >
    > = {
        ...(isArenaStaff
            ? {
                  ' ': (item: ExportedNamespacesAndUseCases) => (
                      <StyledTooltip
                          disabled={item.path_to_artifact.startsWith('s3://')}
                          label="Invalid Artifact Path"
                      >
                          <div>
                              <Checkbox
                                  defaultChecked={false}
                                  onChange={e => {
                                      const val = {
                                          namespace: item.namespace,
                                          use_case: item.use_case,
                                          path_to_artifact: item.path_to_artifact,
                                      };
                                      const id = generateExportID(val);
                                      onCheckClick(prev =>
                                          e.currentTarget?.checked
                                              ? {
                                                    ...prev,
                                                    [id]: val,
                                                }
                                              : Object.entries(prev).reduce(
                                                    (acc, [key, v]) =>
                                                        generateExportID(v) === key
                                                            ? acc
                                                            : { ...acc, [key]: v },
                                                    {},
                                                ),
                                      );
                                  }}
                                  checked={
                                      !!checkedRows?.[
                                          JSON.stringify({
                                              namespace: item.namespace,
                                              use_case: item.use_case,
                                              path_to_artifact: item.path_to_artifact,
                                          })
                                      ] || !item.path_to_artifact.startsWith('s3://')
                                  }
                                  icon={
                                      !item.path_to_artifact.startsWith('s3://')
                                          ? ({ indeterminate, ...v }) => <X {...v} weight="bold" />
                                          : undefined
                                  }
                                  size="sm"
                                  disabled={!item.path_to_artifact.startsWith('s3://')}
                              />
                          </div>
                      </StyledTooltip>
                  ),
              }
            : {}),
        namespace: (item: ExportedNamespacesAndUseCases) =>
            formatNamespaceForDisplay(item.namespace, getGeneratedColor(item.namespace)),
        use_case: (item: ExportedNamespacesAndUseCases) => (
            <BEToolBadge color={USE_CASES_COLORS?.[item.use_case] ?? colors.yellow}>
                {formatUseCaseName(item.use_case)}
            </BEToolBadge>
        ),

        status: (item: ExportedNamespacesAndUseCases) => (
            <BEToolBadge color={STATUS_COLORS?.[item.status] ?? colors.orange}>
                {item.status}
            </BEToolBadge>
        ),
        start_time: (item: ExportedNamespacesAndUseCases) =>
            bannerAssortmentChartDateFormatter(item.start_time, ' HH:MM:SS'),
        last_updated: (item: ExportedNamespacesAndUseCases) =>
            bannerAssortmentChartDateFormatter(item.last_updated, ' HH:MM:SS'),
        no_path_to_recs_list: (item: ExportedNamespacesAndUseCases) =>
            item.path_to_recs_list.length,
        path_to_recs_list: (item: ExportedNamespacesAndUseCases) => (
            <HoverCard>
                <HoverCard.Target>
                    <div className="flex w-full items-center justify-center">
                        <Path />
                    </div>
                </HoverCard.Target>
                <HoverCard.Dropdown className="glass-effect flex max-h-[200px] max-w-[310px] flex-col gap-1 overflow-auto !p-0.5">
                    {item.path_to_recs_list?.map(option => (
                        <Text
                            type="12Reg"
                            key={option}
                            className="p-2 !text-navy-solid-70 hover:bg-primary-4"
                        >
                            {option}
                        </Text>
                    )) ?? 0}
                </HoverCard.Dropdown>
            </HoverCard>
        ),
        ...(isArenaStaff
            ? {
                  path_to_artifact: (item: ExportedNamespacesAndUseCases) =>
                      `${item.path_to_artifact}`,
              }
            : {}),
    };
    return (
        <CommonTable<
            Partial<ExportedNamespacesAndUseCases & { no_path_to_recs_list: number; ' ': string }>
        >
            height={height}
            data={data}
            headers={headers}
            bodyCellsFormatter={bodyCellsFormatter}
            loading={isLoading}
            deepColumnsFetchersForSorting={deepColumnsFetchersForSorting}
            defaultSortingColumn="last_updated"
            onHeightChange={onHeightChange}
            noSorting={{ columns: { ' ': false } }}
        />
    );
}

export default ExportsTable;
