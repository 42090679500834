import React, { useMemo } from 'react';
import { MultiLevelSelect } from 'andromeda';
import {
    MultiLevelMultiSelectType,
    NewTypeSelectValues,
    RangeInputDataType,
} from 'andromeda/types/select/newTypeOfSelect';
import { bannerAssortmentChartDateFormatter } from '@/utils/dateHelpers';
import { formatNumber } from '@/utils/numberFormatHelpers';
import { formatCountriesInAnString } from './utils';
import { formatUseCaseName } from '../utils/UseCaseDropdown';

type HeaderUniq<T> = (arr: T[]) => JSX.Element;

function CommonFilter<T = any>({
    headers,
    data,
    value,
    onChange,
    deepColumnsFetchersForSorting,
    loading,
}: {
    headers: Partial<Record<keyof T, string | HeaderUniq<T>>>;
    value: NewTypeSelectValues;
    data: T[];
    loading: boolean;
    onChange: (value: NewTypeSelectValues) => void;
    deepColumnsFetchersForSorting?: Partial<Record<keyof T, (item: T) => string | number>>;
}) {
    const filterData = useMemo(() => {
        const groupingData =
            data?.reduce((acc: Record<string, Record<string, string | number>>, item) => {
                Object.keys(headers).forEach(key => {
                    if (typeof key !== 'string') return;
                    const val = deepColumnsFetchersForSorting?.[key]?.(item) ?? item[key];

                    if (val !== undefined) {
                        acc[key] = {
                            ...(acc[key] ?? {}),
                            [val]: val,
                        };
                    }
                });

                return acc;
            }, {}) ?? {};
        return Object.entries(groupingData).reduce((acc, [key, valueObj]) => {
            const valArr = Object.values(valueObj);
            if (valArr.length <= 1) return acc;
            const firstItem = valArr[0];
            if (typeof firstItem === 'number') {
                acc[key] = {
                    type: 'range',
                    data: { min: valArr[0], max: valArr[valArr.length - 1], steps: valArr.length },
                    numberFormate(n) {
                        return formatNumber(n, '0,0');
                    },
                } as RangeInputDataType;
            } else if (typeof firstItem === 'string') {
                if (key.includes('time') || key.includes('date')) {
                    const arr = valArr.sort(
                        (a, b) =>
                            new Date(valArr[0]).getTime() -
                            new Date(valArr[valArr.length - 1]).getTime(),
                    );
                    acc[key] = {
                        type: 'range',
                        data: {
                            min: new Date(arr[0]).getTime(),
                            max: new Date(arr[arr.length - 1]).getTime(),
                            steps: valArr.length,
                        },
                        numberFormate(n) {
                            return `${bannerAssortmentChartDateFormatter(new Date(n).toISOString(), `\n HH:MM:SS`)}`;
                        },
                    } as RangeInputDataType;
                } else
                    acc[key] = { type: 'multiSelect', data: valArr } as MultiLevelMultiSelectType;
            }
            return acc;
        }, {});
    }, [data, deepColumnsFetchersForSorting, headers]);

    return (
        <MultiLevelSelect
            data={[filterData]}
            values={value}
            onChange={onChange}
            loading={loading}
            itemsFormattingFunction={(e, key) => {
                if (e === '') return '----';
                if (headers[e]) return headers[e];
                if (key.includes('time') || key.includes('date'))
                    return bannerAssortmentChartDateFormatter(e, ' HH:MM:SS');
                const cappedE = e.toUpperCase();
                if (key === 'id') return e;

                if (key === 'athena_relation' || key === 'namespace')
                    return formatCountriesInAnString(cappedE);
                if (key === 'use_case') return formatUseCaseName(cappedE);
                if (key === 'dataset_type') return e.replace(/_/g, ' ');

                return undefined;
            }}
        />
    );
}

export default CommonFilter;
