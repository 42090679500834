import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCommon } from '@/hooks/useCommon';
import { useColors } from '@/hooks/useColors';
import { Button, Checkbox, Spinner, Text } from 'andromeda';
import { useExportData } from '@/hooks/useUploadData';
import { X } from 'phosphor-react';
import colors from '@/constants/colors';
import { notification } from '@/utils/notification';
import useRouterQuery from '@/hooks/useRouterQuery';
import { useExportedNamespacesAndUseCases } from '@/hooks/useExportedNamespacesAndUseCases';
import { useUserAccess } from '@/hooks/useUserAccess';
import { AnimatePresence, motion } from 'framer-motion';
import { UseCaseDropdown } from '../utils/UseCaseDropdown';
import { NamespaceDropdown } from '../utils/NamespaceDropdown';
import { SpaceportCommonHeader } from '../utils/SpaceportCommonHeader';
import ModelOutputTable from './modelOutputTable';
import TabButton from '../utils/tabButton';
import DatasetsTable from './datasetsTable';
import ExportsTable from './exportsTable';
import { ALL_USED_COLORS } from './utils';
import RegisterHostModel from './registerHostModel';

function CatalogComponent() {
    const colorSet = useRef(ALL_USED_COLORS);
    const isArenaStaff = useUserAccess({ userGroupWhitelist: ['arena-staff'] });

    const { handleURLParamsChange, router } = useRouterQuery<'tab'>();
    const { getColor } = useColors({ overrideColorsSet: Object.values(ALL_USED_COLORS) });

    const getGeneratedColor = useCallback(
        (key: string) => {
            if (key in colorSet.current) return colorSet.current[key];
            const c = getColor(Object.keys(colorSet.current).length);
            Object.assign(colorSet.current, { [key]: c });
            return c;
        },
        [colorSet, getColor],
    );

    const [namespace, setNamespace] = useState('DE');
    const [usecase, setUsecase] = useState(null);
    const [tablesHeights, setTablesHeights] = useState<Partial<Record<typeof tab, number>>>({});
    const [isLatestDataSetData, setIsLatestDataSetData] = useState(false);
    const [exportArtifacts, setExportArtifacts] = useState<
        Record<
            string,
            {
                use_case: string;
                namespace: string;
                path_to_artifact: string;
            }
        >
    >({});
    const [exportArtifactsStates, setExportArtifactsStates] = useState<{
        total: number;
        failed: number;
        success: number;
    }>({
        total: 0,
        failed: 0,
        success: 0,
    });
    const { refetch } = useExportedNamespacesAndUseCases({ namespace, usecase });

    const exportArtifactsArr = Object.values(exportArtifacts);
    const [tab, setTab] = useState<'datasets' | 'model output' | 'export'>('datasets');
    const [showOnly, setShowOnly] = useState<'datasets' | 'model output' | 'export'>('datasets');

    useEffect(() => {
        if (!router.isReady) return;

        if (typeof router.query.tab === 'string') {
            setTab(router.query.tab as 'datasets' | 'model output' | 'export');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.isReady]);

    const { data: allUseCases } = useCommon('useCase');
    const useCasesOptions = useMemo(
        () => allUseCases?.items?.map(item => item.name) ?? [],
        [allUseCases?.items],
    );
    const { mutateAsync: returnExport, isPending } = useExportData({
        setExportArtifactsStates,
        setExportArtifacts,
    });

    return (
        <div>
            <SpaceportCommonHeader
                title="Catalog"
                containerClassName="pl-[27px]"
                description="Data Catalog find all the datasets uploads and exports and their status."
                actionsElement={
                    <div className="flex">
                        <NamespaceDropdown
                            selectedNamespace={namespace}
                            updateSelectedNamespace={e => {
                                setNamespace(e === 'All' ? null : e);
                                setTablesHeights({});
                            }}
                            clearable={isArenaStaff}
                        />
                        <UseCaseDropdown
                            selectedNamespace={namespace}
                            selectedModule={usecase}
                            updateSelectedModule={e => {
                                setUsecase(e === 'All' ? null : e);
                                setTablesHeights({});
                            }}
                            modules={!namespace ? useCasesOptions : undefined}
                            clearable
                        />
                    </div>
                }
            >
                <div className="ml-7 flex items-center justify-between gap-4 pb-2">
                    <div className="flex gap-4">
                        {['datasets', 'model output', 'export'].map(item => (
                            <TabButton
                                key={item}
                                onClick={() => {
                                    handleURLParamsChange({ value: item, key: 'tab' });
                                    setTab(item as typeof tab);
                                }}
                                isActive={tab === item}
                                id="table"
                                className="!w-fit capitalize"
                            >
                                {item}
                            </TabButton>
                        ))}
                    </div>
                    <div className="flex items-center gap-4">
                        <div className="relative flex h-[37px] w-[150px] items-center justify-end">
                            <AnimatePresence mode="wait" initial={false}>
                                <motion.div
                                    layout
                                    animate={{
                                        animation: 'ease',
                                        opacity: tab !== 'datasets' ? 0 : 1,
                                        zIndex: showOnly === 'datasets' ? 0 : -1,
                                    }}
                                    className="absolute"
                                    initial={{ opacity: 0 }}
                                    transition={{ ease: 'easeInOut', duration: 0.25 }}
                                >
                                    <Checkbox
                                        checked={isLatestDataSetData}
                                        onChange={e =>
                                            setIsLatestDataSetData(e.currentTarget?.checked)
                                        }
                                        label="Show Latest Only"
                                        size="sm"
                                    />
                                </motion.div>
                                {isArenaStaff && (
                                    <motion.div
                                        layout
                                        className="absolute flex justify-end"
                                        animate={{
                                            animation: 'ease',
                                            opacity: tab !== 'export' ? 0 : 1,
                                            zIndex: showOnly === 'export' ? 0 : -1,
                                        }}
                                        initial={{ opacity: 0 }}
                                        transition={{ ease: 'easeInOut', duration: 0.25 }}
                                    >
                                        {isPending && (
                                            <div className="absolute right-2 -mt-4 flex gap-1 whitespace-nowrap">
                                                <Spinner size={12} />
                                                <Text type="10Mid" className="capitalize">
                                                    success: {exportArtifactsStates.success}
                                                </Text>
                                                <Text type="10Mid" className="capitalize">
                                                    failed: {exportArtifactsStates.failed}
                                                </Text>
                                                <Text type="10Mid" className="capitalize">
                                                    total:
                                                    {exportArtifactsStates.total -
                                                        exportArtifactsArr.length}
                                                    /{exportArtifactsStates.total}
                                                </Text>
                                            </div>
                                        )}
                                        <Button
                                            onClick={async () => {
                                                setExportArtifactsStates(prev => ({
                                                    ...prev,
                                                    total: exportArtifactsArr.length,
                                                }));
                                                await Promise.all(
                                                    exportArtifactsArr.map((item, i, arr) =>
                                                        returnExport({
                                                            namespace: item.namespace,
                                                            useCase: item.use_case,
                                                            body: {
                                                                path_to_artifact:
                                                                    item.path_to_artifact,
                                                            },
                                                        }),
                                                    ),
                                                );
                                                if (exportArtifactsStates.failed > 0)
                                                    notification({
                                                        message: `${exportArtifactsStates.failed} artifacts Failed to export`,
                                                        icon: <X color={colors.red} size={14} />,
                                                    });
                                                setExportArtifactsStates({
                                                    failed: 0,
                                                    success: 0,
                                                    total: 0,
                                                });
                                                refetch();
                                            }}
                                            inactive={isPending || exportArtifactsArr.length === 0}
                                            disabled={isPending || exportArtifactsArr.length === 0}
                                            disabledTooltipLabel="No export to return"
                                        >
                                            Run Export
                                            {exportArtifactsArr.length > 0 &&
                                                `(${exportArtifactsArr.length})`}
                                        </Button>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                        <RegisterHostModel />
                    </div>
                </div>
            </SpaceportCommonHeader>
            <AnimatePresence mode="wait" initial={false}>
                <div
                    className="relative h-full overflow-hidden"
                    style={{
                        height: `calc(100vh - 173px)`,
                        maxWidth: 'calc(100vw - 200px)',
                        minWidth: 'calc(100vw - 200px)',
                    }}
                >
                    <motion.div
                        layout
                        animate={{
                            animation: 'ease',
                            opacity: tab !== 'datasets' ? 0 : 1,
                            zIndex: showOnly !== 'datasets' ? -1 : 0,
                        }}
                        initial={{ opacity: 0 }}
                        transition={{ ease: 'easeInOut', duration: 0.2, delay: 0.2 }}
                        onAnimationComplete={() => setShowOnly(tab)}
                        className="absolute"
                    >
                        <div
                            style={{ width: 'calc(100vw - 200px)', height: `calc(100vh - 173px)` }}
                        >
                            {showOnly === 'datasets' && (
                                <DatasetsTable
                                    namespace={namespace}
                                    height={tablesHeights.datasets}
                                    getGeneratedColor={getGeneratedColor}
                                    onHeightChange={n =>
                                        setTablesHeights(prev => ({ ...prev, datasets: n }))
                                    }
                                    isLatestDataSetData={isLatestDataSetData}
                                />
                            )}
                        </div>
                    </motion.div>
                    <motion.div
                        layout
                        animate={{
                            animation: 'ease',
                            opacity: tab !== 'model output' ? 0 : 1,
                            zIndex: showOnly !== 'model output' ? -1 : 0,
                        }}
                        onAnimationComplete={() => setShowOnly(tab)}
                        initial={{ opacity: 0 }}
                        transition={{ ease: 'easeInOut', duration: 0.2, delay: 0.2 }}
                        className="absolute"
                    >
                        <div
                            style={{ width: 'calc(100vw - 200px)', height: `calc(100vh - 173px)` }}
                            className="h-full"
                        >
                            {showOnly === 'model output' && (
                                <ModelOutputTable
                                    namespace={namespace}
                                    height={tablesHeights['model output']}
                                    usecase={usecase}
                                    onHeightChange={n =>
                                        setTablesHeights(prev => ({ ...prev, 'model output': n }))
                                    }
                                    getGeneratedColor={getGeneratedColor}
                                />
                            )}
                        </div>
                    </motion.div>
                    <motion.div
                        layout
                        animate={{
                            animation: 'ease',
                            opacity: tab !== 'export' ? 0 : 1,
                            zIndex: showOnly !== 'export' ? -1 : 0,
                        }}
                        onAnimationComplete={() => setShowOnly(tab)}
                        initial={{ opacity: 0 }}
                        transition={{ ease: 'easeInOut', duration: 0.2, delay: 0.2 }}
                        className="absolute"
                    >
                        <div
                            style={{ width: 'calc(100vw - 200px)', height: `calc(100vh - 173px)` }}
                            className="h-full"
                        >
                            {showOnly === 'export' && (
                                <ExportsTable
                                    namespace={namespace}
                                    height={tablesHeights.export}
                                    usecase={usecase}
                                    getGeneratedColor={getGeneratedColor}
                                    onCheckClick={setExportArtifacts}
                                    checkedRows={exportArtifacts}
                                    onHeightChange={n =>
                                        setTablesHeights(prev => ({ ...prev, export: n }))
                                    }
                                    isArenaStaff={isArenaStaff}
                                />
                            )}
                        </div>
                    </motion.div>
                </div>
            </AnimatePresence>
        </div>
    );
}

export default CatalogComponent;
