import { BOREALIES_API_URL } from 'buildConfig/processEnv';
import useApiQuery from './useApiQuery';

const url = `${BOREALIES_API_URL}/spaceport/api/common`;
const urls = {
    namespaces: `${url}/namespaces`,
    useCase: `${url}/use_case`,
};

interface DataType {
    namespaces: string[];
    useCase: {
        items: {
            description: string;
            id: string;
            name: string;
        }[];
        count: number;
    };
}

export const useCommon = <T extends 'namespaces' | 'useCase'>(type: T) =>
    useApiQuery<DataType[T]>({
        url: urls[type],
        method: 'GET',
    });
