/* eslint-disable react/no-unstable-nested-components */
import colors, { grayScale, primaryColors } from '@/constants/colors';
import { bannerAssortmentChartDateFormatter } from '@/utils/dateHelpers';
import { Badge } from 'andromeda';
import React from 'react';
import { DatasetsDataType, useDataSets } from '@/hooks/useDatasets';
import { formatUseCaseName } from '../utils/UseCaseDropdown';
import CommonTable from './commonTable';
import { BEToolBadge, formatNamespaceForDisplay, STATUS_COLORS } from './utils';

const headers = {
    id: 'ID',
    namespace: 'Namespace',
    organization: 'Organization',
    dataset_type: 'Data type',
    status: 'Status',
    status_description: 'Status Description',
    upload_time: 'Upload time',
    athena_relation: 'Athena relation',
    num_rows: 'Num Rows',
    incremental: 'Incremental',
};

const deepColumnsFetchersForSorting = {
    num_rows: (item: DatasetsDataType) => item?.metadata?.num_rows ?? 0,
    incremental: (item: DatasetsDataType) => `${item?.metadata?.incremental ?? '----'}`,
};

function DatasetsTable({
    namespace,
    getGeneratedColor,
    isLatestDataSetData,
    height,
    onHeightChange,
}: {
    namespace: string;
    isLatestDataSetData: boolean;
    getGeneratedColor: (k: string) => string;
    height: number;
    onHeightChange: (n: number) => void;
}) {
    const { data, isLoading } = useDataSets(namespace, isLatestDataSetData);

    const bodyCellsFormatter = {
        namespace: (item: DatasetsDataType) =>
            formatNamespaceForDisplay(item.namespace, getGeneratedColor(item.namespace)),
        organization: (item: DatasetsDataType) => (
            <BEToolBadge color={getGeneratedColor(item.organization)}>
                {item.organization}
            </BEToolBadge>
        ),
        dataset_type: (item: DatasetsDataType) => (
            <BEToolBadge color={grayScale[60]}>{formatUseCaseName(item.dataset_type)}</BEToolBadge>
        ),
        status: (item: DatasetsDataType) => (
            <BEToolBadge color={STATUS_COLORS?.[item.status] ?? colors.orange}>
                {item.status}
            </BEToolBadge>
        ),
        upload_time: (item: DatasetsDataType) =>
            bannerAssortmentChartDateFormatter(item.upload_time, ' HH:MM:SS'),
        num_rows: (item: DatasetsDataType) => item?.metadata?.num_rows ?? '----',
        incremental: (item: DatasetsDataType) => `${item?.metadata?.incremental ?? '----'}`,
    };

    return (
        <CommonTable<
            Partial<
                DatasetsDataType & {
                    num_rows: string;
                    incremental: string;
                }
            >
        >
            data={data}
            headers={headers}
            bodyCellsFormatter={bodyCellsFormatter}
            loading={isLoading}
            deepColumnsFetchersForSorting={deepColumnsFetchersForSorting}
            height={height}
            onHeightChange={onHeightChange}
            defaultSortingColumn="upload_time"
        />
    );
}

export default DatasetsTable;
