import { BOREALIES_API_URL } from 'buildConfig/processEnv';
import useApiQuery from './useApiQuery';

export interface ExportedNamespacesAndUseCases {
    namespace: string;
    use_case: string;
    status: string;
    path_to_artifact: string;
    path_to_recs_list: string[];
    start_time: string;
    last_updated: string;
}

const url = `${BOREALIES_API_URL}/spaceport/api/`;

export const useExportedNamespacesAndUseCases = ({
    namespace,
    usecase,
}: {
    namespace: string;
    usecase: string;
}) =>
    useApiQuery<ExportedNamespacesAndUseCases[]>({
        url: `${url}exports/export${
            namespace || usecase ? '?' : ''
        }${namespace ? `namespace=${namespace}` : ''}${
            namespace && usecase ? '&' : ''
        }${usecase ? `use_case=${usecase}` : ''}`,
        method: 'GET',
    });
