import { motion } from 'framer-motion';
import { CaretDown, CaretUp } from 'phosphor-react';
import React from 'react';
import { grayScale } from '../constants/colors';

type SortingType = 'asc' | 'desc' | false;

function TableHeaderCellCarrot({
    sortingType,
    hasFocus,
}: {
    sortingType?: 'asc' | 'desc' | false;
    hasFocus?: boolean;
}) {
    if (sortingType === false && !hasFocus)
        return <div className="ml-[2px] inline-block size-[16px] min-w-[16px] text-transparent" />;
    if ((hasFocus && !sortingType) || sortingType === 'desc') {
        return (
            <motion.div
                animate={{
                    transform:
                        sortingType === 'desc' && hasFocus ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
                style={{
                    transform:
                        sortingType === 'desc' && hasFocus ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
                transition={{
                    easings: ['easeIn', 'easeOut'],
                    duration: 0.3,
                    delay: 0,
                }}
                className="inline-block"
            >
                <CaretDown
                    size={16}
                    className="ml-[2px] inline-block"
                    color={(sortingType === 'desc' && hasFocus && grayScale[60]) || grayScale[0]}
                />
            </motion.div>
        );
    }

    return (
        <motion.div
            animate={{
                transform: sortingType === 'asc' && hasFocus ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
            style={{
                transform: sortingType === 'asc' && hasFocus ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
            transition={{
                easings: ['easeIn', 'easeOut'],
                duration: 0.3,
                delay: 0,
            }}
            className="inline-block"
        >
            <CaretUp
                className="ml-[2px] inline-block"
                size={16}
                color={(hasFocus && grayScale[80]) || grayScale[0]}
            />
        </motion.div>
    );
}

const CellButton = React.forwardRef(
    (
        {
            children,
            sortingType,
            hasFocus,
            iconLeft,
            ...rest
        }: {
            children: string | JSX.Element;
            iconLeft?: JSX.Element;
            // rightIcon?: JSX.Element;
            sortingType: SortingType;
            hasFocus?: boolean;
        } & React.ButtonHTMLAttributes<HTMLButtonElement>,
        ref: React.LegacyRef<HTMLButtonElement>,
    ) => (
        <button type="button" {...rest} ref={ref}>
            {children}
            {iconLeft}
            <TableHeaderCellCarrot sortingType={sortingType} hasFocus={hasFocus} />
        </button>
    ),
);

const handleCellClickType = (type: SortingType) =>
    type === 'desc' ? 'asc' : (!type && 'desc') || false;

export { TableHeaderCellCarrot, CellButton, handleCellClickType };
export type { SortingType };
